<template>
  <div>
    <div class="flex gap-8" style="margin-bottom: 0.1rem;">
      <LayoutSelect
        placeholder="请选择设备"
        :options="deviceOption"
        :defaultValue="params.deviceId"
        @change="selectChange"
        style="width: 2rem;"
      ></LayoutSelect>
    </div>
    <div style="height: 50vh">
      <PaginationTable
        ref="tableRef"
        row-key="pkId"
        :api="api"
        :columns="columns"
        :scroll="{ x: false, y: 'max-content' }"
        :paginationConfig="{
          size: 'small',
          showQuickJumper: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import PaginationTable from '@/components/pagination-table';
import { nowSize } from '@/utils/common';
import moment from 'moment';
import { LayoutSelect } from '@/views/screen/layout';
import { getHeightFormworkList } from '@/services/screen/jiangsu/height-formwork';

@Component({
  components: { EmptyContent, AsyncSelect, PaginationTable, LayoutSelect },
})
export default class DataListModal extends Vue {
  @Prop({ required: true }) projectId;
  @Prop({ required: true }) deviceId;
  @Prop({ required: true }) deviceOption;
  @Prop({ required: true, type: String }) type;
  params = {
    deviceId: undefined,
  };
  @Watch('params', { deep: true })
  paramsChange() {
    this.$refs.tableRef.search();
  }
  async api({ current, size }) {
    const res = await getHeightFormworkList({
      current,
      size,
      idxProjectId: this.projectId,
      ...this.params,
    });
    return res;
  }
  columns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      width: nowSize(120),
    },
    {
      title: '设备码',
      dataIndex: 'deviceCode',
      width: nowSize(120),
    },
    {
      title: '倾角',
      dataIndex: 'ha',
      width: nowSize(100),
    },
    {
      title: '倾角状态',
      width: nowSize(100),
      dataIndex: 'stAlarmLgqj',
      customRender: text => ['正常', '预警', '报警'][text],
    },
    {
      title: '沉降',
      dataIndex: 'vert',
      width: nowSize(100),
    },
    {
      title: '沉降状态',
      dataIndex: 'stAlarmCj',
      width: nowSize(120),
      customRender: text => ['正常', '预警', '报警'][text],
    },
    {
      title: '位移',
      dataIndex: 'hor',
      width: nowSize(100),
    },
    {
      title: '位移状态',
      dataIndex: 'stAlarmSp',
      width: nowSize(100),
      customRender: text => ['正常', '预警', '报警'][text],
    },
    {
      title: '重量',
      dataIndex: 'weight',
      width: nowSize(100),
    },
    {
      title: '重量状态',
      dataIndex: 'stAlarmWei',
      width: nowSize(100),
      customRender: text => ['正常', '预警', '报警'][text],
    },
    {
      title: '上报时间',
      dataIndex: 'lastUpdateTime',
      width: nowSize(120),
      customRender: text =>
        text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
    },
  ];

  selectChange(val) {
    this.params.deviceId = val;
  }

  created() {
    this.params.deviceId = this.deviceId;
  }
}
</script>

<style lang="less" module></style>
