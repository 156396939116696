import request from '../../request';

const serviceName = '/smart-site/jiangsu/screen/high';

/**
 * 高支模监测数据
 * @param {string} deviceId 设备ID
 */
export function getHeightFormworkLatestData(deviceId) {
  return request(`${serviceName}/lastData`, {
    method: 'GET',
    body: {
      deviceId,
    },
  });
}

/**
 * 高支模数据列表
 * @param {object} body
 * @param {number} body.current
 * @param {number} body.size
 * @param {number} body.idxProjectId 项目ID
 * @param {number} body.deviceId 项目ID
 */
export function getHeightFormworkList(body) {
  return request(`${serviceName}/page`, {
    method: 'POST',
    body,
  });
}

/**
 * 高支模趋势图数据
 * @param {object} body
 * @param {'SEVEN_DAY' | 'THIRTY_DAY'} body.days 日期筛选
 * @param {number} body.deviceId 设备ID
 * @param {number} body.projectId 项目ID
 * @param {'WEIGHT' | 'HOR' | 'VERT'} body.sensor 传感器类型 VERT:沉降 HOR:位移 WEIGHT:重量
 */
export function getHeightFormworkTrend(body) {
  return request(`${serviceName}/trend`, {
    method: 'GET',
    body,
  });
}

/**
 * 高支模警报
 * @param {object} body
 * @param {number} body.projectId 项目ID
 * @param {number} body.deviceId 设备ID
 * @param {'SEVEN_DAY' | 'THIRTY_DAY'} body.days 最近天数
 */
export function getHeightFormworkAlarm(body) {
  return request(`${serviceName}/alarm/rank/list`, {
    method: 'POST',
    body,
  });
}
